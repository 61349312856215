@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter Tight';
  src: url('./InterTight-VariableFont_wght.ttf') format('ttf')
}

body {
  font-family: 'Inter Tight', sans-serif !important;
  overflow-y: hidden;
  /* filter: url('#wavy'); */
}

.slow-fade-in {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: forwards;
  opacity: 0;
}

.very-slow-fade-in {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: forwards;
  animation-delay: 2s;
  opacity: 0;
}

.slow-fade-out {

  animation-name: fadeOut;
  animation-duration: 2s;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 1;
}

.filter-blue {
  filter: invert(42%) sepia(82%) saturate(445%) hue-rotate(172deg) brightness(94%) contrast(86%);

}

.skull {
  filter: url('#wavy') invert(42%) sepia(82%) saturate(445%) hue-rotate(172deg) brightness(94%) contrast(86%);
  position: fixed;
  z-index: -1;
  opacity: 0.1;
  top: 0;
  animation-name: fadeInSkull;
  animation-duration: 5s;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: forwards;
}

.dbd-logo {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  bottom: 1rem;

}

.dbd-logo img {
  width: 240px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInSkull {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}